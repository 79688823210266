import React, {useState, useEffect, useReducer} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import i18next from "i18next";
import {initReactI18next, useTranslation} from "react-i18next";

const resources = {
    en: {
        translation: {
            dough_amount: "Amount of the dough (g)",
            tdp: "TDP",
            flours: "Flours",
            others: "Ingredients",
            add: "Add",
            flour: "flour",
            other: "ingredient"
        }
    },
    pl: {
        translation: {
            dough_amount: "Ilość ciasta (g)",
            tdp: "CPC",
            flours: "Mąki",
            others: "Składniki",
            add: "Dodaj",
            flour: "mąkę",
            other: "składnik",
        }
    },
}

const recipes = {
    pl: {
        "0": {
            weight: 1920,
            groups: {
                flours: [
                    ["Pszenna", 80],
                    ["Razowa", 20],
                ],
                others: [
                    ["Woda", 70],
                    ["Sól", 2],
                    ["Zakwas", 20],
                ]
            },
        },
    },
    en: {
        "0": {
            weight: 1920,
            groups: {
                flours: [
                    ["Wheat bread flour", 80],
                    ["Whole wheat flour", 20],
                ],
                others: [
                    ["Water", 70],
                    ["Salt", 2],
                    ["Levain", 20],
                ]
            },
        },
    },
};

const percentageTargets = {
    'flours': 100.0,
}

const localRecipesKey = "localRecipes";

function groupsReducer(state, newGroups) {
    return Object.assign({}, state, newGroups);
}

function Calculator(props) {
    const { i18n } = useTranslation();
    useEffect(() => {
        i18n.changeLanguage(props.lang);
    }, [i18n, props.lang]);

    /*
        const localRecipesFromStorage = window.localStorage.getItem(localRecipesKey);
        let localRecipes 
        if (localRecipesFromStorage) {
            localRecipes = JSON.parse(localRecipesFromStorage);
        } else {
            localRecipes = [];
        }
     */

    const [totalDoughWeightText, setTotalDoughWeightText] = useState("");
    const [totalDoughWeight, setTotalDoughWeight] = useState(0);
    const [groupedIngredients, updateGroupedIngredients] = useReducer(groupsReducer, {
        "flours": [],
        "others": [],
    });
    const [ingredientsWeights, updateIngredientsWeights] = useReducer(groupsReducer, {});
    const [totalDoughPercentage, setTotalDoughPercentage] = useState(0.0);

    useEffect(() => {
        const recipe = recipes[i18n.language]["0"];

        setTotalDoughWeight(recipe.weight);
        setTotalDoughWeightText(recipe.weight);

        const groupedIngredients = {};
        Object.keys(recipe.groups).forEach(group => {
            const recipeIngredients = recipe.groups[group];
            groupedIngredients[group] = recipeIngredients.map(ingredient => {
                const [name, percentage] = ingredient;
                return createIngredient(name, percentage);
            })
        });
        updateGroupedIngredients(groupedIngredients);
    }, [i18n.language]);

    useEffect(() => {
        let result = 0.0;
        Object.values(groupedIngredients).forEach(ingredients => {
            ingredients.forEach(ingredient => {
                result += ingredient.percentage;
            });
        });
        setTotalDoughPercentage(result);
    }, [groupedIngredients]);

    useEffect(() => {
        Object.keys(groupedIngredients).forEach(group => {
            const update = {};
            groupedIngredients[group].forEach(ingredient => {
                let value = 0.0;
                if (totalDoughPercentage > 0.0) {
                    value = ingredient.percentage * totalDoughWeight / totalDoughPercentage;
                }
                update[ingredient.id] = value;
            });
            updateIngredientsWeights(update);
        });
    }, [groupedIngredients, totalDoughWeight, totalDoughPercentage])

    const onTotalDoughWeightChange = (event) => {
        const text = event.target.value;

        let value = Number(text);
        if (isNaN(value)) {
            value = 0.0;
        }

        setTotalDoughWeightText(text);
        setTotalDoughWeight(value);
    }

    const createIngredient = (name, percentage) => {
        return {
            id: uuidv4(),
            name: name,
            percentageText: toFixedFloat(percentage),
            percentage: percentage,
        }
    }

    const onAddIngredient = (event, group) => {
        const ingredients = groupedIngredients[group];
        const newIngredients = ingredients.concat([
            createIngredient("", 0.0),
        ]);

        updateGroupedIngredients({
            [group]: newIngredients,
        });
    }

    const onIngredientChange = (event, group, id) => {
        const ingredients = groupedIngredients[group];
        const index = ingredients.findIndex(i => i.id === id);

        if (index < 0) {
            console.error("Expected to find an ingredient by ID")
            return;
        }

        const name = event.target.name;
        let newIngredient = {};

        if (name === "name") {
            newIngredient.name = event.target.value;
        } else if (name === "percentage") {
            const text = event.target.value;
            let percentage = Number(text.replaceAll(",", "."));
            if (isNaN(percentage)) {
                percentage = 0.0;
            }
            newIngredient.percentageText = text;
            newIngredient.percentage = percentage;
        } else {
            console.error("Unknown changed element name: " + name);
            return
        }

        const newIngredients = ingredients.map((value) => {
            if (value.id === id)  {
                return Object.assign({}, value, newIngredient);
            }

            return value;
        });

        updateGroupedIngredients({
            [group]: newIngredients,
        });
    }

    const onDeleteIngredient = (event, group, id) => {
        const ingredients = groupedIngredients[group];
        const newIngredients = ingredients.filter(i => i.id !== id);

        updateGroupedIngredients({
            [group]: newIngredients,
        });
    }

        /*
    componentDidMount() {
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        const urlRecipe = params.get('recipe');
        if (urlRecipe) {
            this.loadRecipe(null, JSON.parse(b64DecodeUnicode(urlRecipe)));
        }
    }
         */

    const mappedIngredients = Object.assign({}, groupedIngredients);
    Object.keys(mappedIngredients).forEach(group => {
        const ingredients = mappedIngredients[group];
        mappedIngredients[group] = ingredients.map(ingredient => {
            let weight = ingredientsWeights[ingredient.id];
            if (!weight) {
                weight = 0.0;
            }
            ingredient.weight = weight;
            return ingredient;
        });
    });

    return (
        <div className="calc">
            <CalculatorHeader
                totalDoughWeight={totalDoughWeightText}
                onTotalDoughWeightChange={onTotalDoughWeightChange}
                totalDoughPercentage={totalDoughPercentage}
            />
            {Object.keys(groupedIngredients).map((group) =>
                <IngredientsTable
                    key={group}
                    group={group}
                    ingredients={mappedIngredients[group]}
                    onAddIngredient={onAddIngredient}
                    onIngredientChange={onIngredientChange}
                    onDeleteIngredient={onDeleteIngredient}
                    target={percentageTargets[group]}
                />
            )}
        </div>
    );
}

        /*<button onClick={this.clearRecipe}>Wyczyść</button>
                <ul>
                    <li><a href="#" onClick={e => this.loadRecipe(e, recipes["0"])}>Chleb wiejski</a></li>
                    <li><a href="#" onClick={e => this.loadRecipe(e, recipes["1"])}>Chleb żytni</a></li>
                </ul>
                <input type="text" />
                <button onClick={this.saveRecipe}>Zapisz</button>
                <ul>
                    {this.state.localRecipes.map((recipe, index) =>
                       <li key={index}><a href="#" onClick={e => this.loadRecipe(e, recipe)}>Nazwa</a></li> 
                    )} 
                </ul>
                <button onClick={this.shareRecipe}>Share</button>
                <a href={this.state.shareURL}>{this.state.shareURL}</a>

    clearRecipe = (event) => {
        this.setState({
            totalDoughWeightText: "0",
            totalDoughWeight: 0,
            groupedIngredients: {
                "flours": [],
                "others": [],
            },
        });
        
        this.clearShareURL();
    }

    marshalRecipe() {
        const groups = {};
        Object.keys(this.state.groupedIngredients).forEach(group => {
            groups[group] = this.state.groupedIngredients[group].map(ingredient => {
                return [
                    ingredient.name,
                    ingredient.percentage,
                ];
            });
        });

        return {
            weight: this.state.totalDoughWeight,
            groups: groups,
        };
    }


saveRecipe = (event) => {
    const recipe = this.marshalRecipe();
    const newRecipes = this.state.localRecipes.concat([recipe]);

    this.setState({
        localRecipes: newRecipes,
    });

    window.localStorage.setItem(localRecipesKey, JSON.stringify(newRecipes));
}

loadRecipe = (event, recipe) => {
    // TODO
    if (event) {
        event.preventDefault();
    }

    const groupedIngredients = {};
    Object.keys(recipe.groups).forEach(group => {
        const recipeIngredients = recipe.groups[group];
        const ingredients = recipeIngredients.map(ingredient => {
            return this.newIngredient(ingredient[0], ingredient[1]);
        })

        groupedIngredients[group] = ingredients;
    });

    this.setState({
        totalDoughWeight: recipe.weight,
        totalDoughWeightText: toFixedFloat(recipe.weight),
        groupedIngredients: groupedIngredients,
    });

    this.clearShareURL();
}

shareRecipe = (event) => {
    const recipe = this.marshalRecipe();
    const recipeJSON = JSON.stringify(recipe);
    const recipeB64 = b64EncodeUnicode(recipeJSON);
    const url = window.location.protocol + "//" + window.location.host + "/?recipe="+ recipeB64;

    this.setState({
        shareURL: url,
    });
}

clearShareURL() {
    this.setState({
        shareURL: "",
    });
}

 */

function CalculatorHeader(props) {
    const { t } = useTranslation();

    return (
        <>
            <div className="cell cell-wide text-right">{t("dough_amount")}</div>
            <div className="cell">
                <input
                    type="text"
                    step="1"
                    value={props.totalDoughWeight}
                    onChange={props.onTotalDoughWeightChange}
                />
            </div>
            <div className="cell cell-wide text-right">{t("tdp")}</div>
            <div className="cell">{toFixedFloat(props.totalDoughPercentage)}%</div>
        </>
    );
}

function IngredientsTable(props) {
    let summedPercentage = 0.0;
    let summedWeight = 0.0;

    props.ingredients.forEach(ingredient => {
        summedPercentage += ingredient.percentage;
        summedWeight += ingredient.weight;
    })

    const { t } = useTranslation();

    return (
        <>
            <IngredientsSummary
                group={t(props.group)}
                percentage={summedPercentage}
                weight={summedWeight}
                target={props.target}
            />
            {props.ingredients.map((ingredient, index) =>
                <IngredientRow
                    key={ingredient.id}
                    group={props.group}
                    ingredient={ingredient}
                    onChange={props.onIngredientChange}
                    onDeleteIngredient={props.onDeleteIngredient}
                />
            )}
                <div className="cell cell-wide">
                    <AddButton
                        group={props.group}
                        onClick={props.onAddIngredient}
                    />
                </div>
        </>
    );
}

function IngredientsSummary(props) {
    const { t } = useTranslation();

    const targetClass = () => {
        if (props.target && props.percentage !== 0.0 && props.percentage !== props.target) {
            return " error";
        }

        return "";
    }

    return (
        <>
            <div className="cell cell-wide cell-inner cell-header">{t(props.group)}</div>
            <div className={"cell cell-inner cell-header"+ targetClass()}>{toFixedFloat(props.percentage)}%</div>
            <div className="cell cell-inner cell-header">{props.weight.toFixed()}g</div>
        </>
    );
}

function IngredientRow(props) {
    return (
        <>
          <div className="cell cell-inner text-center">
              <DeleteButton
                  id={props.ingredient.id}
                  group={props.group}
                  onClick={props.onDeleteIngredient}
              />
          </div>
          <div className="cell cell-inner">
              <input
                  name="name"
                  type="text"
                  value={props.ingredient.name}
                  onChange={(event) => props.onChange(event, props.group, props.ingredient.id)}
              />
          </div>
          <div className="cell cell-inner">
              <input
                  name="percentage"
                  type="text"
                  step="0.1"
                  value={props.ingredient.percentageText}
                  onChange={(event) => props.onChange(event, props.group, props.ingredient.id)}
              />
          </div>
          <div className="cell cell-outer">
              {props.ingredient.weight.toFixed()}g
          </div>
        </>
    );
}

function DeleteButton(props) {
    return (
        <button
            className="button-delete"
            onClick={(event) => props.onClick(event, props.group, props.id)}
        >-</button>
    );
}

function AddButton(props) {
    const { t } = useTranslation();

    let name;
    switch (props.group) {
        case "flours":
            name = "flour";
            break;
        case "others":
            name = "other";
            break;
        default:
            name = "";
    }

    return (
        <button
            className="button-add"
            onClick={(event) => props.onClick(event, props.group)}
        >{t("add")} {t(name)}</button>
    );
}

i18next
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

let root = document.getElementById('baker-calc');
if (root) {
    ReactDOM.render(
        <React.StrictMode>
            <Calculator lang={root.getAttribute("lang")} />
        </React.StrictMode>,
        root,
    );
}

function toFixedFloat(number) {
    return Math.round(number*10)/10
}

// https://stackoverflow.com/a/2117523
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        // eslint-disable-next-line
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

// https://stackoverflow.com/a/30106551
function b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
}

function b64DecodeUnicode(str) {
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}
